.homepage-v3 {
  width: 100%;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-image: url("/public/homepage@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  /* min-width: 1440px; */

  /* max-width: 1440px; */
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (width <= 1425px) {
  .homepage-v3 {
    max-width: 100%;
  }
}

@media screen and (width <= 1200px) {
  .homepage-v3 {
    max-width: 100%;
  }
}

@media screen and (width <= 825px) {
  .homepage-v3 {
    max-width: 100%;
  }
}

@media screen and (width <= 450px) {
  .homepage-v3 {
    max-width: 100%;
  }
}
