.couch-testimonies1 {
  position: relative;

  /* top: 4120px; */
  margin-top: 100px; 
  margin-bottom: 100px; 

  /* bottom: 0;  */

  /* left: 0; */
  margin: auto; 
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  gap: 20px 18px;
  max-width: 100%;
}
