.cosmo-jupiter-1-icon {
  height: 185.2px;
  width: 190px;
  position: relative;
  object-fit: cover;
}

.learn-more-about5 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 54px;
  font-weight: 700;
  font-family: inherit;
}

.welcome-to-cosmo2 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 28px;
}

.start-today {
  flex: 1;
  position: relative;
  font-size: var(--font-size-5xl);
  line-height: 20px;
  font-weight: 800;
  font-family: var(--font-jetbrains-mono);
  color: var(--color-white);
  text-align: center;
  display: inline-block;
  max-height: 23.02px;
}

.button6 {
  width: 229px;
  border-radius: var(--br-mid);
  background-color: var(--color-purple-200);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-8xl-4) var(--padding-smi) var(--padding-7xl-6);
  box-sizing: border-box;
  min-width: 120.08px;
  white-space: nowrap;
}

.divchakra-stack6,
.link9 {
  display: flex;
  align-items: flex-start;
}

.link9 {
  width: 162px;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-11xs-5) 0 var(--padding-11xs-5) 0;
  box-sizing: border-box;
}

.divchakra-stack6 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 229px;
  flex-direction: row;
  justify-content: flex-start;
}

.learn-more-about-us-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-36xl);
  box-sizing: border-box;
  gap: var(--gap-16xl);
  min-width: 407px;
  max-width: 100%;
  margin-left: -2px;
}

.cosmo-rocket-1-icon {
  height: 257px;
  width: 190px;
  position: relative;
  object-fit: contain;
  margin-left: -2px;
}

.unlock-potential,
.unlock-your-potential {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}

.unlock-potential {
  margin: calc(5% + 20px) 0; 
}

.unlock-your-potential {
  flex: 1;
  border: 1px solid var(--color-black);
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: var(--padding-39xl) var(--padding-66xl);
}

.unlock-potential {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-71xl);
  text-align: center;
  font-size: var(--font-size-25xl);
  color: var(--color-white);
  font-family: var(--font-inter);
}

@media screen and (width <= 1200px) {
  .learn-more-about-us-group {
    padding-left: var(--padding-8xl);
    padding-right: var(--padding-8xl);
    box-sizing: border-box;
  }
}

@media screen and (width <= 850px) {
  .learn-more-about5 {
    font-size: var(--font-size-16xl);
    line-height: 43px;
  }

  .learn-more-about-us-group {
    gap: var(--gap-mid);
  }

  .unlock-your-potential {
    padding: var(--padding-19xl) var(--padding-23xl);
    box-sizing: border-box;
  }

  .unlock-potential {
    padding-bottom: var(--padding-39xl);
    box-sizing: border-box;
  }
}

@media screen and (width <= 450px) {
  .learn-more-about5 {
    font-size: var(--font-size-7xl);
    line-height: 32px;
  }

  .learn-more-about-us-group {
    min-width: 100%;
  }

  .unlock-your-potential {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
