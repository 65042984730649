@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@500;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --font-inter: inter;
  --font-lato: lato;
  --font-jetbrains-mono: "JetBrains Mono";

  /* font sizes */
  --font-size-smi-5: 12.5px;
  --font-size-base-4: 15.4px;
  --font-size-xs-6: 11.6px;
  --font-size-13xl: 32px;
  --font-size-lgi: 19px;
  --font-size-7xl: 26px;
  --font-size-xl: 20px;
  --font-size-base: 16px;
  --font-size-5xl: 24px;
  --font-size-sm: 14px;
  --font-size-3xl: 22px;
  --font-size-lg: 18px;
  --font-size-11xl: 30px;
  --font-size-17xl: 36px;
  --font-size-10xl: 29px;
  --font-size-161xl: 180px;
  --font-size-26xl: 45px;
  --font-size-53xl: 72px;
  --font-size-21xl: 40px;
  --font-size-25xl: 44px;
  --font-size-16xl: 35px;
  --font-size-xs: 12px;
  --font-size-lgi-3: 19.3px;
  --font-size-19xl-5: 38.5px;
  --font-size-4xl: 23px;
  --font-size-12xl: 31px;

  /* Responsive texts */
  --font-size-heading: 2em; 
  --font-size-semiheading: 1.5em; 
  --font-size-description: 1em; 
  --font-size-medium: 0.75em;
  --font-size-semimedium: 0.6em;  
  --font-size-small: 0.5em; 

    /* Responsive REM texts */
    --font-size-heading: 2rem; 
    --font-size-semiheading: 1.5em; 
    --font-size-description: 1em; 
    --font-size-medium: 0.75em;
    --font-size-semimedium: 0.6em;  
    --font-size-small: 0.5em; 

  /* Responsive lines */ 
  --line-height-small: calc(20px + 0.5em); 
  --line-height-medium: 1em; 
  --line-height-large: 1.5em; 


  /* Responsive paddings */ 
  --padding-btn-lg-v: 1.5em; 
  --padding-btn-lgmed-v: 1em; 
  --padding-btn-med-v: 0.25em; 
  --padding-btn-sm-v: 0.1em; 
  --padding-btn-lg-h: 1em; 
  --padding-btn-med-h: 0.5em; 
  --padding-btn-sm-h: 0.25em; 
  --padding-btn-t-h: 0.1em; 

    /* Responsive buttons */ 
    --large-button-width: 3.5em; 
    --medium-button-width: 0.25em; 
    --small-button-width: 0.1em; 
    --large-button-height: 1em; 
    --medium-button-height: 0.5em; 
    --small-button-height: 0.25em; 
    --tiny-button-height: 0.1em; 

  /* Colors */
  --color-tan-100: #e6b5a6;
  --color-tan: #b4ad93;
  --color-tan-200: rgb(230 181 166 / 9%);
  --color-black: #000;
  --color-midnightblue-100: #3f2787;
  --color-midnightblue-400: #46136f;
  --color-midnightblue-200: #3e166c;
  --color-midnightblue-300: rgb(28 3 58 / 90%);
  --color-darkslateblue-100: #755895;
  --color-darkslateblue-200: #7a47a3;
  --color-darkslateblue-300: rgb(122 71 163 / 9%);
  --color-lightgray-100: #d1d7de;
  --color-lightgray-200: #cdd5df;
  --color-white: #fff;
  --color-gainsboro-100: #e6e6e6;
  --color-gainsboro-200: #d3e4e8;
  --color-silver: #b3bdc7;
  --color-dodgerblue-100: #4582e7;
  --color-dodgerblue-200: #228beb;
  --color-slateblue: #664dad;
  --color-seashell: #fdf1ec;
  --color-goldenrod-100: #ffc648;
  --color-goldenrod-500: #f8c450;
  --color-goldenrod-200: #cc9414;
  --color-goldenrod-300: rgb(204 148 20 / 9%);
  --color-cadetblue: #5da596;
  --color-mediumpurple: #9772e7;
  --color-khaki: #fae060;
  --color-papayawhip: #f8e9d0;
  --color-purple-100: #54106c;
  --color-purple-200: #5e0066;
  --color-sandybrown: #f5c470;
  --color-peachpuff: #ffcebf;
  --color-powderblue: #bfe6ee;
  --color-lightcyan: #d7fffb;
  --color-beige: #e6efd3;

  /* Gaps */
  --gap-21xl: 40px;
  --gap-xl: 20px;
  --gap-lgi: 19px;
  --gap-18xl: 37px;
  --gap-base-6: 15.6px;
  --gap-2xs-3: 10.3px;
  --gap-mini: 15px;
  --gap-66xl: 85px;
  --gap-23xl: 42px;
  --gap-base-4: 15.4px;
  --gap-65xl: 84px;
  --gap-2xl: 21px;
  --gap-3xs: 10px;
  --gap-13xl: 32px;
  --gap-base: 16px;
  --gap-16xl: 35px;
  --gap-mid: 17px;
  --gap-4xl: 23px;
  --gap-2xs-5: 10.5px;
  --gap-4xs: 9px;
  --gap-36xl: 55px;
  --gap-8xl: 27px;
  --gap-134xl: 153px;
  --gap-19xl: 38px;
  --gap-57xl: 76px;
  --gap-3xl: 22px;
  --gap-xs: 12px;
  --gap-27xl: 46px;
  --gap-38xl: 57px;
  --gap-5xs: 8px;
  --gap-17xl: 36px;
  --gap-41xl: 60px;
  --gap-11xl: 30px;
  --gap-7xl: 26px;
  --gap-lgi-4: 19.4px;
  --gap-81xl: 100px;
  --gap-6xl: 25px;
  --gap-31xl: 50px;
  --gap-20xl: 39px;
  --gap-51xl: 70px;
  --gap-28xl: 47px;
  --gap-26xl: 45px;
  --gap-9xl: 28px;
  --gap-lg: 18px;
  --gap-46xl-5: 65.5px;
  --gap-3xs-6: 9.6px;

  /* Paddings */
  --padding-11xl: 30px;
  --padding-25xl: 44px;
  --padding-101xl: 120px;
  --padding-26xl: 45px;
  --padding-xl: 20px;
  --padding-41xl: 60px;
  --padding-91xl-8: 110.8px;
  --padding-8xl: 27px;
  --padding-36xl: 55px;
  --padding-12xs-9: 0.9px;
  --padding-5xs: 8px;
  --padding-4xs-2: 8.2px;
  --padding-6xs: 7px;
  --padding-base-2: 16.2px;
  --padding-sm: 14px;
  --padding-mini: 15px;
  --padding-base-4: 15.4px;
  --padding-smi-5: 12.5px;
  --padding-9xs: 4px;
  --padding-lg-8: 18.8px;
  --padding-54xl: 73px;
  --padding-28xl-2: 47.2px;
  --padding-17xl: 36px;
  --padding-11xs-5: 1.5px;
  --padding-smi-8: 12.8px;
  --padding-2xs-4: 10.4px;
  --padding-3xs-6: 9.6px;
  --padding-69xl-6: 88.6px;
  --padding-3xl: 22px;
  --padding-6xs-9: 6.9px;
  --padding-6xs-1: 6.1px;
  --padding-10xs-7: 2.7px;
  --padding-11xs-3: 1.3px;
  --padding-12xs-6: 0.6px;
  --padding-10xs-2: 2.2px;
  --padding-xs: 12px;
  --padding-8xs: 5px;
  --padding-19xl: 38px;
  --padding-3xs: 10px;
  --padding-4xl: 23px;
  --padding-5xl: 24px;
  --padding-16xl: 35px;
  --padding-108xl-5: 127.5px;
  --padding-64xl: 83px;
  --padding-35xl: 54px;
  --padding-4xs: 9px;
  --padding-12xs-5: 0.5px;
  --padding-4xs-8: 8.8px;
  --padding-10xs-5: 2.5px;
  --padding-3xs-1: 9.1px;
  --padding-3xs-5: 9.5px;
  --padding-40xl: 59px;
  --padding-11xs: 2px;
  --padding-15xl: 34px;
  --padding-2xl-5: 21.5px;
  --padding-31xl: 50px;
  --padding-5xl-5: 24.5px;
  --padding-46xl: 65px;
  --padding-52xl: 71px;
  --padding-51xl: 70px;
  --padding-47xl: 66px;
  --padding-44xl: 63px;
  --padding-71xl: 90px;
  --padding-181xl: 200px;
  --padding-39xl: 58px;
  --padding-81xl: 100px;
  --padding-6xl: 25px;
  --padding-8xl-4: 27.4px;
  --padding-sm-4: 13.4px;
  --padding-7xl-6: 26.6px;
  --padding-sm-6: 13.6px;
  --padding-base-5: 15.5px;
  --padding-base: 16px;
  --padding-61xl: 80px;
  --padding-33xl: 52px;
  --padding-166xl-8: 185.8px;
  --padding-74xl: 93px;
  --padding-27xl: 46px;
  --padding-smi: 13px;
  --padding-9xs-6: 3.6px;
  --padding-13xl: 32px;
  --padding-10xs: 3px;
  --padding-14xl: 33px;
  --padding-12xl: 31px;
  --padding-57xl: 76px;
  --padding-20xl: 39px;
  --padding-58xl-5: 77.5px;
  --padding-7xs: 6px;
  --padding-24xl: 43px;
  --padding-110xl: 129px;
  --padding-45xl: 64px;
  --padding-108xl: 127px;
  --padding-142xl: 161px;
  --padding-49xl: 68px;
  --padding-5xs-7: 7.7px;
  --padding-lg-2: 18.2px;
  --padding-30xl-1: 49.1px;
  --padding-29xl: 48px;
  --padding-26xl-2: 45.2px;
  --padding-mid-3: 17.3px;
  --padding-10xl-8: 29.8px;
  --padding-10xl: 29px;
  --padding-7xl: 26px;
  --padding-24xl-3: 43.3px;
  --padding-lg-1: 18.1px;
  --padding-34xl: 53px;
  --padding-242xl: 261px;
  --padding-111xl: 130px;
  --padding-33xl-9: 52.9px;
  --padding-53xl: 72px;
  --padding-49xl-4: 68.4px;
  --padding-48xl-4: 67.4px;
  --padding-36xl-9: 55.9px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-4xl: 23px;
  --br-9xs: 4px;
  --br-25xl: 44px;
  --br-mid: 17px;
  --br-31xl: 50px;
  --br-xl: 20px;
  --br-46xl: 65px;
  --br-mini: 15px;
  --br-mini-4: 14.4px;
}
