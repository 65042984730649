.what-makes-us1 {
  margin: 0;
  width: 1454px;
  position: relative;
  font-size: inherit;
  line-height: 54px;
  font-weight: 700;
  font-family: inherit;
  align-items: center;
  justify-content: center;
}

.coach-features,
.what-makes-us1,
.why-join {
  display: flex;
  max-width: 100%;
}

.coach-features {
  width: 1454px;

  /* height: 1059px;  */
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 85px 57px;
  font-size: var(--font-size-xl);
  color: var(--color-black);
}

.why-join {
  /* position: absolute; */
  position: relative;  
  margin-top: 200px; 
  margin-bottom: 50px; 
  border-radius: var(--br-mini);
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-38xl);
  text-align: center;
  font-size: var(--font-size-25xl);
  color: var(--color-white);
  font-family: var(--font-inter);
}

.why-feature {
  height: 470px;
  width: 400px;
  position: relative;
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  justify-content: flex-start;
  margin: 0 auto;
  padding-top: 10px;  

  /* margin: 0 !important; */

  /* top: 0;
  left: 149.5px; */
  box-shadow: 10px 10px 4px rgb(0 0 0 / 25%);
  border-radius: var(--br-25xl);
  background-color: var(--color-papayawhip);
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-black);
  font-family: var(--font-inter);
}

.one-on-one-1-icon2 {
  position: relative; 
  width: 60%; 

}

.feature-descriptions {
  text-align: center; 
  width: 90%; 
}

.craft-individualized-strategie{
  position: relative;
  line-height: 54px;
  margin: auto; 

}

.our-coaches-design {
  /* align-self: stretch; */
  position: relative;

  /* z-index: 1; */
  margin: auto; 
  font-size: var(--font-size-sm);
  line-height: 28.5px; 
  text-align: center; 
}

@media screen and (width <= 1050px) {
  .what-makes-us1 {
    font-size: var(--font-size-16xl);
    line-height: 43px;
  }

  .why-feature {
    width: 300px; 
  }
}

@media screen and (width <= 750px) {
  .why-join {
    gap: var(--gap-9xl);
  }

  .what-makes-us1 {
    font-size: var(--font-size-7xl);
    line-height: 32px;
  }
}

@media screen and (width <= 450px) {
  .what-makes-us1 {
    font-size: var(--font-size-7xl);
    line-height: 32px;
  }
}
