.accordion-faqs {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.3px;
  line-height: 44px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  min-width: 350px;
  max-width: 100%;
  padding-bottom: 40px; 
}

.accordion-faqs-parent {
  align-self: stretch;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xl);
  max-width: 100%;
}

.description,
.question-1-child {
  width: 788px;
  position: relative;
  border-top: 1px solid var(--color-tan);
  max-width: 100%;
  padding: 30px 0; 
  text-align: justify;  
  transition: max-height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.question-1 {
  width: 928px;
  border-radius: var(--br-base);
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-3xl) var(--padding-4xl);
  gap: var(--gap-5xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-white);
  font-family: var(--font-lato);
}

.question-1-child {
  max-height: 100%;
}

.description {
  font-size: var(--font-size-xl);
  letter-spacing: 0.3px;
  line-height: 44px;
  font-weight: 600;
  display: none; 
  color: white;  

 /* max-height: 0; */

  /* opacity: 0; */

  /* overflow: hidden; */
}

.description.show {
  max-height: 500px;

  /* opacity: 1; */
  display: inline-block; 
}

@media screen and (width <= 850px) {
  .accordion-faqs {
    font-size: var(--font-size-7xl);
    line-height: 35px;
    min-width: 100%;
  }
}

@media screen and (width <= 450px) {
  .accordion-faqs {
    font-size: var(--font-size-lgi);
    line-height: 26px;
    min-width: 200px;
  }

  .description {
    font-size: var(--font-size-base);
    line-height: 35px;
  }
}
