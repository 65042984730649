.rectangle-div {
  height: 277px;
  width: 350px;
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--color-gainsboro-200);
  display: none;
  max-width: 100%;
}

.alex-r-msc-in-applied-math,
.helping-students-see,
.mit {
  margin: 0;
}

.helping-students-see-container {
  height: 179px;
  flex: 1;
  position: relative;
  line-height: 28px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.rectangle-container {
  position: absolute;
  top: 50px;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-gainsboro-200);
  width: 350px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-57xl) var(--padding-14xl) var(--padding-3xl);
  box-sizing: border-box;
  max-width: 100%;
}

.wrapper-ellipse-3-child {
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  transform: scale(1.22);
}

.wrapper-ellipse-3 {
  position: absolute;
  top: 0;
  left: 125px;
  border-radius: var(--br-xl);
  width: 100px;
  height: 100px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.couch-testimony-2 {
  height: 327px;
  width: 350px;
  position: relative;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--color-black);
  font-family: var(--font-inter);
}
