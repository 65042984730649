.image-7-icon2 {
  width: 233.9px;
  position: relative;
  flex-shrink: 0;
  object-fit: cover;
  min-height: 112px;
}

.image-7-icon2,
.image-8-icon2,
.image-9-icon2 {
  align-self: stretch;
  max-height: 100%;
}

.image-8-icon2 {
  width: 173.9px;
  position: relative;
  flex-shrink: 0;
  object-fit: cover;
  min-height: 112px;
}

.image-9-icon2 {
  width: 217.9px;
}

.image-11-icon2,
.image-12-icon2,
.image-9-icon2 {
  position: relative;
  flex-shrink: 0;
  object-fit: cover;
  min-height: 112px;
}

.image-11-icon2 {
  align-self: stretch;
  width: 163.9px;
  max-height: 100%;
}

.image-12-icon2 {
  height: 112px;
  width: 109.9px;
}

.image-13-icon2,
.image-14-icon2 {
  align-self: stretch;
  max-height: 100%;
}

.image-13-icon2 {
  width: 194.9px;
  position: relative;
  flex-shrink: 0;
  object-fit: cover;
  min-height: 112px;
}

.image-14-icon2 {
  width: 184.9px;
}

.image-14-icon2,
.image-7-icon3,
.image-8-icon3 {
  position: relative;
  flex-shrink: 0;
  object-fit: cover;
  min-height: 112px;
}

.image-7-icon3 {
  align-self: stretch;
  width: 233.9px;
  max-height: 100%;
}

.image-8-icon3 {
  width: 173.9px;
}

.image-11-icon3,
.image-8-icon3,
.image-9-icon3 {
  align-self: stretch;
  max-height: 100%;
}

.image-9-icon3 {
  width: 217.9px;
  position: relative;
  flex-shrink: 0;
  object-fit: cover;
  min-height: 112px;
}

.image-11-icon3 {
  width: 163.9px;
}

.image-11-icon3,
.image-12-icon3,
.image-13-icon3,
.image-14-icon3 {
  position: relative;
  flex-shrink: 0;
  object-fit: cover;
  min-height: 112px;
}

.image-15-icon2 {
  position: relative;   
  width: 200px; 

}

.image-16-icon2 {
  position: relative;
  flex-shrink: 0;
  height: 100px;

}

.image-12-icon3 {
  height: 112px;
  width: 109.9px;
}

.image-13-icon3,
.image-14-icon3 {
  align-self: stretch;
  width: 194.9px;
  max-height: 100%;
}

.image-14-icon3 {
  width: 184.9px;
}

.best-colleges1,
.colleges-list1 {
  /* overflow-x: auto; */
  overflow-x: clip;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  /* justify-content: flex-start; */
  justify-content: flex-start;
  max-width: 100%;
}

.best-colleges1 {
  /* width: 1694px; */
  width: 100%;
  flex-shrink: 0;
  gap: var(--gap-81xl);

  /* debug_commit: 1de1738; */
}


.colleges-list1 {
  position: relative;
  margin: 3em auto;

  /* left: 0; */
  width: 100%;
  background-color: var(--color-powderblue);
}

/* .colleges-list1 {
  position: absolute;
  top: 5120px;
  /* left: 0; */

  /* margin-left: auto;
  margin-right: auto;
  width: 100%;
  background-color: var(--color-powderblue);
} */ 


/* keyframe animations */
.first {
  animation: bannermove 20s linear infinite;
  animation: bannermove 20s linear infinite;
  animation: bannermove 20s linear infinite;
  animation: bannermove 20s linear infinite;
  animation: bannermove 20s linear infinite;
}

@keyframes bannermove {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -1000px;
  }

}

@keyframes bannermove {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -1000px;
  }

}

.best-colleges1 img {
  transition: all 0.25s ease;
  transition: all 0.25 ease;
  transition: all 0.25s ease;
  transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.best-colleges1 img:hover {
  transform: scale(1.1);
  transform: scale(1.1);
  transform: scale(1.1);
  transform: scale(1.1);
  transform: scale(1.1);
  cursor: pointer;

  /* -webkit-box-shadow: 0px 3px 5px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 3px 5px rgba(0,0,0,0.2);
  box-shadow: 0px 3px 5px rgba(0,0,0,0.2); */
}