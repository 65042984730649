.features {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex: 1;
  background: linear-gradient(180deg, #fec15f 100%, rgb(255 255 255 / 50%));
  height: auto;
  max-width: 100%;
  text-align: center;
  font-size: 26px;
  color: var(--color-black);
  font-family: var(--font-inter);
  overflow: clip visible;
}

.wrapper-progress-icon {
  align-self: stretch;
  height: 60px;
  position: relative;
  width: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-icon {
  align-self: stretch;
  height: auto;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: fill;
  position: absolute;
  left: -3%;
  top: 0;
  width: 110%;
  min-width: 110%;
  transform: translateY(-50%);
}

.btn-1 {
  font-family: Hack, monospace;
  background: #0F0F6D;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 0.8rem;
  border: 0;
  transition: all 0.5s;
  border-radius: 10px;
  width: auto;
  position: relative;
  margin-bottom: 30px;
}

.btn-1::after {
  content: '\2193';
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  position: absolute;
  inset: 31% 5% 0 85%;
  opacity: 0;
}

.btn-1:hover {
  background: #2b2bff;
  box-shadow: 0 6px 15px #0000ff61;
  padding: 1rem 2rem 1rem 1rem;
}

.btn-1:hover::after {
  opacity: 1;
}

.btn-1:active {
  background: black;
}

.btn-1:focus .feature-text-box {
  display: inherit; 
}

.feature-text-box {
  opacity: 0;
  max-height: 0; 
  transition: opacity 0.5s ease, max-height 0.5s ease;
  overflow: hidden;
}

.feature-text-box.show {
  opacity: 1;
  max-height: 200px; 
}

@media screen and (width >= 2000px) {
  .progress-icon {
    display: none;
  }
}

.features-flexbox {
  display: grid;
  box-sizing: border-box;
  height: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto-fit, minmax(300px, auto));
  gap: 60px;
  font-size: var(--font-size-medium);
  min-height: 800px;
  padding-bottom: 100px; /* not beyond the the section */
}


.single-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 25px;
  box-sizing: border-box;
  /* gap: var(--gap-17xl); */
  box-shadow: 10px 10px 4px rgb(0 0 0 / 25%);
  border-radius: var(--br-25xl);
  background-color: var(--color-papayawhip);
  transition: transform 0.5s ease, max-height 0.5s ease;
  overflow: hidden;
  max-height: 300px; 
  max-width: 310px;
  
}

.single-feature.expanded {
  transform: scale(1.05);
  max-height: 540px; 
  font-size: 15px;
  overflow: visible;

}

.single-feature-img {
  height: 12vw;
  min-height: 120px; 
  max-height: 180px; 
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 1;
}

.features-unlocking-cosmic {
  z-index: 99;
}

@media screen and (width <= 600px) {
  .features-flexbox {
    grid-template-columns: repeat(1, 1fr) !important; /* Default to 1 column */
  }
}

@media screen and (width <= 1200px)  {
  .features-flexbox {
    grid-template-columns: repeat(2, 1fr);
  }
}


/* .progress-icon {
    align-self: stretch;
    height: 100%;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    transform: scale(4.067);
  }
  .wrapper-progress-icon {
    align-self: stretch;
    height: 45px;
    position: relative;
    max-width: 100%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .features-unlocking-cosmic {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: inherit;
    line-height: 28px;
    font-weight: 700;
    font-family: inherit;
  }
  .on-1-child {
    align-self: stretch;
    height: 487px;
    position: relative;
    box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.25);
    border-radius: var(--br-25xl);
    background-color: var(--color-papayawhip);
    display: none;
  }
  .one-on-one-1-icon {
    height: 178px;
    flex: 1;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    object-fit: cover;
    z-index: 1;
  }
  .progress-tracking2 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 var(--padding-44xl);
  }
  .personalized-1-on-1-private {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: inherit;
    line-height: 28px;
    font-weight: 700;
    font-family: inherit;
    z-index: 1;
  }
  .tailored-learning-our-container {
    height: 171px;
    flex: 1;
    position: relative;
    line-height: 24px;
    display: flex;
    align-items: center;
    z-index: 1;
  }
  .personalized-classes,
  .tailored-learning {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .tailored-learning {
    flex-direction: row;
    padding: 0 var(--padding-5xl) 0 var(--padding-5xl-5);
    font-size: var(--font-size-sm);
  }
  .personalized-classes {
    flex-direction: column;
    gap: var(--gap-5xs);
  }
  .on-1,
  .project-learning-child {
    box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.25);
    border-radius: var(--br-25xl);
    background-color: var(--color-papayawhip);
  }
  .on-1 {
    width: 347px;
    margin: 0 !important;
    position: absolute;
    top: 44px;
    left: 153.5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-47xl) 0 0;
    box-sizing: border-box;
    gap: var(--gap-17xl);
  }
  .project-learning-child {
    align-self: stretch;
    height: 487px;
    position: relative;
    display: none;
  }
  .planning-icon {
    height: 179px;
    width: 165px;
    position: relative;
    object-fit: cover;
    z-index: 1;
  }
  .project-image {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0 var(--padding-xl);
  }
  .project-based-learning {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: inherit;
    line-height: 28px;
    font-weight: 700;
    font-family: inherit;
    z-index: 1;
  }
  .project-image-container {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-17xl);
  }
  .boost-your-skills-container {
    height: 171px;
    flex: 1;
    position: relative;
    line-height: 24px;
    display: flex;
    align-items: center;
    z-index: 1;
  }
  .project-description,
  .project-learning {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .project-description {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    padding: 0 var(--padding-5xl) 0 var(--padding-5xl-5);
    font-size: var(--font-size-sm);
  }
  .project-learning {
    width: 347px;
    margin: 0 !important;
    position: absolute;
    top: 44px;
    left: 546.5px;
    box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.25);
    border-radius: var(--br-25xl);
    background-color: var(--color-papayawhip);
    flex-direction: column;
    padding: var(--padding-46xl) 0 0;
    gap: var(--gap-5xs);
  }
  .safety-supportive-child {
    align-self: stretch;
    height: 487px;
    position: relative;
    display: none;
  }
  .safety-icon {
    height: 179px;
    width: 165px;
    position: relative;
    object-fit: cover;
    z-index: 1;
  }
  .safety-image {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0 var(--padding-xl);
  }
  .safety-supportive-h3 {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: inherit;
    line-height: 28px;
    font-weight: 700;
    font-family: inherit;
    z-index: 1;
  }
  .safety-image-container {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-17xl);
  }
  .cosmic-community-container {
    height: 171px;
    flex: 1;
    position: relative;
    line-height: 24px;
    display: flex;
    align-items: center;
    z-index: 1;
  }
  .safety-description,
  .safety-supportive {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .safety-description {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    padding: 0 var(--padding-5xl) 0 var(--padding-5xl-5);
    font-size: var(--font-size-sm);
  }
  .safety-supportive {
    width: 347px;
    margin: 0 !important;
    position: absolute;
    top: 180px;
    left: 546.5px;
    box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.25);
    border-radius: var(--br-25xl);
    background-color: var(--color-papayawhip);
    flex-direction: column;
    padding: var(--padding-46xl) 0 0;
    gap: var(--gap-5xs);
  }
  .features,
  .on-1-parent,
  .project-learning {
    display: flex;
    box-sizing: border-box;
  }
  .on-1-parent {
    align-self: stretch;
    height: 1114px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: var(--padding-25xl) 0 var(--padding-31xl);
    position: relative;
    gap: var(--gap-27xl);
    font-size: var(--font-size-3xl);
  }
  .features {
    display: flex; 
    flex: 1;
    background: linear-gradient(180deg, #f5c470 35%, rgba(255, 255, 255, 0));
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-2xl-5) 0;
    gap: var(--gap-19xl);
    max-width: 100%;
    text-align: center;
    font-size: var(--font-size-21xl);
    color: var(--color-black);
    font-family: var(--font-inter);
  }
  @media screen and (max-width: 1425px) {
    .features {
      padding-top: var(--padding-xl);
      padding-bottom: var(--padding-xl);
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 825px) {
    .features-unlocking-cosmic {
      font-size: var(--font-size-13xl);
      line-height: 22px;
    }
    .features {
      gap: var(--gap-lgi);
    }
  }
  @media screen and (max-width: 450px) {
    .features-unlocking-cosmic {
      font-size: var(--font-size-5xl);
      line-height: 17px;
    }
    .personalized-1-on-1-private,
    .project-based-learning {
      font-size: var(--font-size-lg);
      line-height: 22px;
    }
  }
   */