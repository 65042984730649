.the-first-step {
  margin: 0;
}

.starts-here {
  margin: 0;
  color: var(--color-sandybrown);

  /* color: black;  */
}

.the-first-step-container {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 72px;
  font-weight: 500;
  font-family: inherit;
}

.discover-personalized-programm {
  width: 680px;
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 28px;
  font-family: var(--font-inter);
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 104%;
  flex-shrink: 0;
}

.start-learning {
  position: relative;
  font-size: var(--font-size-5xl); 
  /*font-size: var(--font-size-base);*/ 
  line-height: 20px;
  font-weight: 800;
  font-family: var(--font-jetbrains-mono);
  color: var(--color-white);
  text-align: center;
  display: inline-block;
  max-height: 23.000001907348633px;
}

.button7,
.divchakra-stack7,
.link8 {
  display: flex;
  justify-content: center;
}

.button7 {
  border-radius: var(--br-mid);
  background-color: var(--color-purple-100);
  flex-direction: row;
  align-items: center;
  justify-content: center; 
  padding: var(--padding-btn-lg-v)  var(--padding-btn-lg-h) ; 

  /* padding: var(--padding-large-button-top) var(--padding-) */

  /* padding: var(--padding-8xl-4) var(--padding-sm-4) var(--padding-7xl-6)
    var(--padding-sm-6); */
  box-sizing: border-box;
  min-width: 119.99999237060547px;

  /* transform: translateX(-10%);  */
  white-space: nowrap;
}

.button7:hover {
  background-color: #9417E2;
}

.divchakra-stack7,
.link8 {
  align-items: center;
}

.link8 {
  width: 162px;
  flex-direction: column;
  padding: var(--padding-11xs-5) 0 var(--padding-11xs-5) 0;
  box-sizing: border-box;
}

.divchakra-stack7 {
  cursor: pointer;
  border: 0;
  padding: 0 var(--padding-xl);
  background-color: transparent;
  align-self: stretch;
  flex-flow: row wrap;
}

.the-first-step-into-your-cosmi-parent {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-19xl) 50px;
  box-sizing: border-box;
  gap: var(--gap-7xl);

  /* min-width: 428px; */
  max-width: 100%;
}

.scholar-kid-2-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
}

.top-down-grad {
  background: linear-gradient(
    180deg,
    #000,
    rgb(0 0 0 / 20%) 8.22%,
    transparent 17.07%,
    rgb(0 0 0 / 10%) 52.08%,
    rgb(0 0 0 / 20%) 85.82%,
    #000
  );
}

.left-right-grad,
.top-down-grad,
.top-left-linear-grad {
  position: absolute;
  top: calc(50% - 234.6px);
  right: 6.9px;
  width: 100%;
  height: 100%;
  display: none;
}

.left-right-grad {
  background: linear-gradient(
    90deg,
    #000,
    rgb(0 0 0 / 20%) 7.23%,
    transparent 18.23%,
    transparent 82.3%,
    rgb(0 0 0 / 20%) 90.61%,
    #000
  );
}

.top-left-linear-grad {
  background: linear-gradient(
    135.48deg,
    #000,
    transparent 26.72%,
    transparent 44.27%,
    transparent 82.3%,
    rgb(0 0 0 / 20%) 90.61%,
    #000
  );
}

.top-right-linear-grad {
  background: linear-gradient(
    224.52deg,
    #000,
    transparent 23.94%,
    transparent 44.27%,
    transparent 82.3%,
    rgb(0 0 0 / 20%) 90.61%,
    #000
  );
}

.astro-dude-platform-1,
.top-right-linear-grad {
  position: absolute;
  top: calc(50% - 238.5px);
  right: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.scholar-kid-2-parent {
  height: 477px;
  width: 476px;
  position: relative;
  background-image: url("/public/scholarkid-2@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-width: 476px;
  max-width: 100%;
}

.hero {
  align-self: stretch;
  background: linear-gradient(
    -90deg,
    rgb(0 0 0 / 77%),
    rgb(0 0 0 / 3%) 99.99%,
    transparent
  );
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;

  /* align-items: flex-start;
  justify-content: flex-start; */
  margin-top: 80px; 
  align-items: center; 
  justify-content: center;

  /* padding: var(--padding-71xl) var(--padding-26xl) var(--padding-71xl)
    var(--padding-26x1); */
  box-sizing: border-box;
  gap: var(--gap-41xl);
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--color-white);
  font-family: var(--font-jetbrains-mono);
  padding-left: 66px; 
  padding-right: 66px; 

  /* transform: translateX(-5%); */
  margin-bottom: 250px; 
}

@media screen and (width <= 1200px) {
  .scholar-kid-2-parent {
    /* flex: 1; */
    display: none; 
  }

  .hero {
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 60px; 
    padding-right: 60px; 
  }

}

@media screen and (width <= 825px) {
  .the-first-step-container {
    font-size: var(--font-size-13xl);
    line-height: 58px;
  }

  .the-first-step-into-your-cosmi-parent {
    padding: var(--padding-6xl) 40px var(--padding-6xl) 40px; 
    box-sizing: border-box;

    /* min-width: 100%; */
  }

  .scholar-kid-2-parent {
    min-width: 100%;
  }

  .hero {
    gap: var(--gap-11xl);

    /* padding: var(--padding-39xl) var(--padding-3xl) var(--padding-39xl) */

      /* var(--padding-3xl); */
    padding-left: 80px !important; 
    padding-right: 80px;  
    box-sizing: border-box;
  }
}

@media screen and (width <= 450px) {
  .the-first-step-container {
    font-size: var(--font-size-5xl);
    line-height: 43px;
  }

  .discover-personalized-programm {
    font-size: var(--font-size-base);
    line-height: 22px;
  }

  .hero {
    gap: var(--gap-mini);
    box-sizing: border-box;
    padding-left: 40px; 
    padding-right: 40px;  
  }
}
