.learn-more-about2 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 54px;
  font-weight: 700;
  font-family: inherit;
}

.information-we-collect {
  margin: 0;
  font-size: var(--font-size-17xl);
}

.personal-information {
  margin: 0;
  font-size: var(--font-size-5xl);
  font-weight: 600;
}

.payment-information-processed {
  margin-bottom: 0;
}

.name-email-address-phone-num {
  margin: 0;
  padding-left: var(--padding-5xl);
}

.usage-data {
  margin: 0;
  font-size: var(--font-size-5xl);
}

.how-we-use-your-information {
  margin: 0;
  font-size: var(--font-size-13xl);
}

.we-implement-a {
  margin: 0;
}

.introduction-welcome-to-container1 {
  align-self: stretch;
  position: relative;
  line-height: 28px;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: var(--font-size-lg);
}

.intro-content,
.intro-content-wrapper {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.intro-content {
  width: 1021px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-16xl);
}

.intro-content-wrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-25xl);
  color: var(--color-white);
  font-family: var(--font-inter);
}

.privacy-policy1 {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-106xl);
  background-image: url("/public/background.jpg");
  background-size: 100%;
  background-repeat: repeat-y;
  background-position: top;
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (width <= 1050px) {
  .privacy-policy1 {
    gap: var(--gap-43xl);
  }
}

@media screen and (width <= 750px) {
  .learn-more-about2 {
    font-size: var(--font-size-16xl);
    line-height: 43px;
  }

  .intro-content {
    gap: var(--gap-mid);
  }
}

@media screen and (width <= 450px) {
  .learn-more-about2 {
    font-size: var(--font-size-7xl);
    line-height: 32px;
  }

  .privacy-policy1 {
    gap: var(--gap-12xl);
  }
}