.cosmo-logo-invert-original-1-icon {
  height: 72px;
  width: 72px;
  position: relative;
  object-fit: cover;
  min-height: 72px;
}

.cosmo-logo-invert-original-1-icon:hover {
  animation: rotateY 1s 1 linear; 
}

@keyframes rotateY {
  to { transform: rotateY(360deg); }
}

.cosmo-logo-edit-child {
  align-self: stretch;
  width: 281px;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  min-height: 72px;
}

.cosmo-logo-edit-child:hover {
  cursor: pointer; 
}

.cosmo-logo-edit {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-6xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  max-width: 100%;
}

.link-about {
  display: inline-block;
  min-width: 60px;
}

.link-about,
.link-become,
.link-courses,
.link-parents {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 72px;
  font-weight: 600;
  cursor: pointer;
}

.link-courses {
  display: inline-block;
  min-width: 81px;
}

.link-become,
.link-parents {
  white-space: nowrap;
}

.divdivider-vertical {
  width: 1px;
  height: 40px;
  position: relative;
  background-color: var(--color-white);
}

.link-divider {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base) 0 0;
}

.book-an-appointment1 {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.49px;
  line-height: 41px;
  font-weight: 600;
  font-family: var(--font-inter);
  color: var(--color-white);
  text-align: center;
  white-space: nowrap;
}

.appointment-button-container,
.link7 {
  display: flex;
  align-items: flex-start;
  justify-content: center;

}

.link7 {
  cursor: pointer;
  border: 1px solid var(--color-goldenrod-100);
  padding: 0 var(--padding-3xs);
  background-color: transparent;
  align-self: stretch;
  flex-direction: row;
}

.link7:hover {
  background-color: var(--color-goldenrod-300);
  border: 1px solid var(--color-goldenrod-200);
  box-sizing: border-box;
}

.appointment-button-container {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-base-5) 0 0;
}

.navbar,
.navigation-links {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.navigation-links {
  width: 805px;
  justify-content: flex-start;
  gap: var(--gap-lgi-4);
}

.link-hover:hover{
    color: 	#f5c470 !important; 
}

.navbar {
  align-self: stretch;
  backdrop-filter: blur(8px);
  background-color: var(--color-midnightblue-300);
  justify-content: space-between;
  padding: 0 var(--padding-xl) 0 0;
  box-sizing: border-box;
  top: 0;
  z-index: 99;
  position: sticky;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-inter);
}

.hamburger-menu {
  display: none; /* Hide hamburger menu on larger screens */
  transform: translateY(5px);
}

.hamburger-menu div {
  width: 25px;
  height: 3px;
  background-color: var(--color-white);
  margin: 5px 0;
  transition: 0.4s; ;
}

@media screen and (width <= 1240px) {
  /* .navbar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    text-align: center; 
    flex-wrap:wrap; 
  } */

  .navbar {
    font-size: 0.8rem; 
  }

  .cosmo-logo-invert-original-1-icon{
    width: 60px; 
    height: 60px; 
    object-fit: contain; 
    min-height: 50px;  
  }

  .cosmo-logo-edit-child {
    align-self: stretch;
    width:240px;
    position: relative;
    max-height: 90%;
    object-fit: cover;
    min-height: 60px;
  }

}



@media screen and (width <= 1100px) {
  .navbar {
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    text-align: center; 
  }

  .cosmo-logo-edit {
    justify-content:flex-start;
    padding: var(--padding-md);
  }
  
  .cosmo-logo-invert-original-1-icon{
    width: 50px; 
    height: 50px; 
    object-fit: contain; 
    min-height: 50px;  
  }

  .cosmo-logo-edit-child {
    align-self: stretch;
    width: 200px;
    position: relative;
    max-height: 100%;
    object-fit: cover;
    min-height: 50px;
  }

  .navigation-links {
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: none; /* Initially hide the navigation links */
    overflow: hidden; /* Hide overflowing content */
    transition: opacity 3s ease;
    opacity: 0; /* Initial opacity for transition */

  }

  .navigation-links.show {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-top: white solid 1px; 
    opacity: 1; /* Make visible */
  }

  .navigation-links > div {
    width: 100%;
    text-align: center;
    padding: var(--padding-md) 0;
    font-size: 1rem;
  }

  .appointment-button-container {
    width: 100%;
    display: flex; 
    justify-content: center;
    text-align: center; 
  }

  .link-divider {
    display: none; 
  }

  .hamburger-menu {
    display: block;
    cursor: pointer;
    padding: var(--padding-md);
    z-index: 1001;
  }

  .hamburger-menu.open div:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  
  .hamburger-menu.open div:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger-menu.open div:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  
}


/* @media screen and (max-width: 1200px) {
  .navigation-links {
    display: none;
  }
} */
