.button-dropdown-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-6xl);
  background-color: var(--color-white);
  width: 100%;
  height: 100%;
}

.button-dropdown-item {
  position: absolute;
  top: 20px;
  left: 15px;
  width: 20px;
  height: 10px;
  z-index: 1;
}

.button-dropdown {
  height: 50px;
  width: 50px;
  position: relative;
}

.button-dropdown-item.rotated { 
  transform: rotate(180deg); 
} 