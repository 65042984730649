.at-cosmo-we2,
.learn-more-about4 {
  width: 1130px;
  position: relative;
  line-height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

.at-cosmo-we2 {
  font-size: 23.1px;
  line-height: 43.31px;
}

.courses,
.learn-more-about-cosmo5 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.learn-more-about-cosmo5 {
  align-self: stretch;
  background-color: rgb(87 51 126 / 78%);
  align-items: center;
  padding: var(--padding-51xl) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-16xl);
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-19xl-5);
  color: var(--color-white);
  font-family: var(--font-inter);
  margin-top: 5rem; 
  margin-bottom: 5rem; 
}

.courses {
  width: 100%;
  position: relative;
  overflow-y: auto;
  align-items: flex-start;
  gap: var(--gap-81xl);
  background-image: url("/public/background.jpg");
  background-size: 100%;
  background-repeat: repeat-y;
  /* background-size: cover; */
  /* background-repeat: no-repeat;
  background-position: top; */
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (width <= 1050px) {
  .learn-more-about4 {
    font-size: var(--font-size-12xl);
    line-height: 35px;
  }

  .courses {
    gap: var(--gap-31xl);
  }
}

@media screen and (width <= 750px) {
  .learn-more-about-cosmo5 {
    gap: var(--gap-mid);
  }
}

@media screen and (width <= 450px) {
  .learn-more-about4 {
    font-size: var(--font-size-4xl);
    line-height: 26px;
  }

  .at-cosmo-we2 {
    font-size: var(--font-size-lg);
    line-height: 35px;
  }

  .courses {
    gap: var(--gap-6xl);
  }
}
