.testimonial-quotes-child {
  height: 277px;
  width: 350px;
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--color-beige);
  display: none;
  max-width: 100%;
}

.every-line-of {
  margin: 0;
}

.priya-k-bs,
.stanford-university {
  margin: 0;
  font-weight: 600;
}

.every-line-of-container {
  height: 222px;
  flex: 1;
  position: relative;
  line-height: 28px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.testimonial-quotes {
  position: absolute;
  top: 50px;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-beige);
  width: 350px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-36xl) var(--padding-14xl) 0;
  box-sizing: border-box;
  max-width: 100%;
}

.wrapper-ellipse-2-child {
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  transform: scale(1.22);
}

.wrapper-ellipse-2 {
  position: absolute;
  top: 0;
  left: 125px;
  border-radius: var(--br-xl);
  width: 100px;
  height: 100px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.couch-testimony-1 {
  height: 327px;
  width: 350px;
  position: relative;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--color-black);
  font-family: var(--font-inter);
}
