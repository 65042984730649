.span {
  text-decoration: underline;
}

.a {
  color: inherit;
}

.heading-2-container {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 28px;
  font-weight: 700;
  font-family: inherit;
}

.divcss-usqjix,
.review-slider {
  align-self: stretch;
  box-sizing: border-box;
  max-width: 100%;
}

.divcss-usqjix {
  width: 765px;
  border-bottom: 4px solid var(--color-darkslateblue-100);
}

.review-slider {
  height: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
}

.h1 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 140px;
  font-weight: 900;
  font-family: inherit;
}

.quote-mark {
  width: 81px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-15xl) 0 0;
  box-sizing: border-box;
}

.heading-42,
.i-cant-express {
  position: relative;
  line-height: 28px;
}

.i-cant-express {
  align-self: stretch;
}

.heading-42 {
  color: var(--color-cadetblue);
}

.i-cant-express-how-amazing-my-parent,
.quote-mark-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.i-cant-express-how-amazing-my-parent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-xs);
  min-width: 335px;
  font-size: var(--font-size-lg);
  color: var(--color-white);
}

.quote-mark-parent {
  align-self: stretch;
  flex-flow: row wrap;
  padding: 0 var(--padding-11xs) 0 0;
  box-sizing: border-box;
  gap: 22px 20px;
}

.h11 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 140px;
  font-weight: 900;
  font-family: inherit;
}

.wrapper {
  width: 81px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-15xl) 0 0;
  box-sizing: border-box;
}

.cosmos-calculus-course {
  /* align-self: stretch; */
  align-self: contain;
  height: auto;
  position: relative;
  line-height: 28px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.heading-43 {
  position: relative;
  font-size: var(--font-size-lgi);
  line-height: 28px;
  color: var(--color-cadetblue);
  margin-top: 10px; 
}

.cosmos-calculus-course-was-a-parent {
  /* flex: 1; */

  /* flex: 0; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  /* gap: var(--gap-xs); */
  gap: min(100% - 5vw, 30px); 
  min-width: 335px;
  max-width: 100%;
  font-size: var(--font-size-lg);
  color: var(--color-white);
}

.frame-parent {
  flex: 1;
  flex-flow: row wrap;
  padding: 0 var(--padding-11xs) 0 0;
  box-sizing: border-box;
  gap: 22px 20px;
  max-width: 100%;
}

.frame-parent,
.reviews-content,
.support-content,
.support-content-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.support-content-inner {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-10xs-5);
  box-sizing: border-box;
  max-width: 100%;
  color: var(--color-mediumpurple);
}

.reviews-content,
.support-content {
  flex-direction: column;
}

.support-content {
  align-self: stretch;
  gap: var(--gap-134xl);
  max-width: 100%;
}

.reviews-content {
  flex: 1;
  padding: var(--padding-40xl) 0 0;
  box-sizing: border-box;
  max-width: calc(100% - 282px);
}

.frame-item {
  height: 326px;
  width: 225px;
  position: relative;
  background-color: var(--color-cadetblue);
  display: none;
}

.girl-review-1-icon {
  height: 308.1px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 1;
}

.rectangle-parent,
.review-image {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.rectangle-parent {
  flex: 1;
  background-color: var(--color-cadetblue);
  padding: var(--padding-3xs-1) var(--padding-3xs-5) var(--padding-4xs-8);
}

.review-image {
  align-self: stretch;
  padding: 0 0 0 var(--padding-10xs-5);
}

.frame-inner {
  height: 326px;
  width: 225px;
  position: relative;
  background-color: var(--color-cadetblue);
  display: none;
}

.girl-review-1-icon1 {
  position: absolute;
  height: calc(100% - 0.1px);
  top: 0.1px;
  bottom: 0;
  left: 9px;
  max-height: 100%;
  width: 205.7px;
  object-fit: cover;
  z-index: 1;
}

.boy-review-1-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}

.student-images {
  height: 308.2px;
  width: 214.7px;
  position: relative;
}

.image-container,
.rectangle-group,
.reviews-content-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.rectangle-group {
  align-self: stretch;
  background-color: var(--color-cadetblue);
  flex-direction: row;
  padding: var(--padding-4xs) var(--padding-12xs-5) var(--padding-4xs-8);
}

.image-container,
.reviews-content-parent {
  width: 227.5px;
  flex-direction: column;
  gap: var(--gap-16xl);
}

.reviews-content-parent {
  width: 1035px;
  flex-direction: row;
  gap: var(--gap-36xl);
  max-width: 100%;
}

.review-header-inner {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-161xl);
  color: var(--color-khaki);
}

.review-header,
.review-header-inner,
.review-header-wrapper {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.review-header {
  width: 1260px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-16xl);
}

.review-header-wrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-108xl-5);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--color-white);
  font-family: var(--font-inter);
}

@media screen and (width <= 1200px) {
  .review-header-wrapper {
    padding-bottom: var(--padding-64xl);
    box-sizing: border-box;
  }
}

@media screen and (width <= 825px) {
  .heading-2-container {
    font-size: var(--font-size-13xl);
    line-height: 22px;
  }

  .h1,
  .h11 {
    font-size: var(--font-size-53xl);
    line-height: 84px;
  }

  .support-content {
    gap: var(--gap-57xl);
  }

  .reviews-content {
    padding-top: var(--padding-19xl);
    box-sizing: border-box;
    max-width: 100%;
  }

  .image-container {
    display: none;
  }

  .reviews-content-parent {
    gap: var(--gap-8xl);
  }

  .review-header {
    gap: var(--gap-mid);
  }
}

@media screen and (width <= 450px) {
  .heading-2-container {
    font-size: var(--font-size-5xl);
    line-height: 17px;
  }

  .h1,
  .h11 {
    font-size: var(--font-size-26xl);
    line-height: 56px;
  }

  .support-content {
    gap: var(--gap-19xl);
  }

  .review-header-wrapper {
    padding-bottom: var(--padding-35xl);
    box-sizing: border-box;
  }
}
