.features-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-108xl-5);
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
}

@media screen and (width <= 1425px) {
  .features-wrapper {
    padding-bottom: var(--padding-64xl);
    box-sizing: border-box;
  }
}

@media screen and (width <= 825px) {
  .features-wrapper {
    padding-bottom: var(--padding-35xl);
    box-sizing: border-box;
  }
}
