.button {
    /* width: 229px; */
    border-radius: var(--br-mid);
    background-color: var(--color-purple-200);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    /* padding: var(--padding-8xl-4) var(--padding-sm-4) var(--padding-7xl-6)
      var(--padding-sm-6); */

    /* padding: 0.5rem 1rem;  */
    padding: var(--padding-btn-lgmed-v)  var(--padding-btn-lg-h) ;
    box-sizing: border-box;

    /* min-width: 120.08333587646484px; */
    white-space: nowrap;
    font-size: var(--font-size-semiheading); 
  }

  .button:hover {
    background-color: #9417E2;
  }
  
  .btn-container,
  .link {
    display: flex;
    align-items: center;
  }

  .link {
    width: 162px;
    flex-direction: column;
    justify-content: center;
    padding: var(--padding-11xs-5) 0 var(--padding-11xs-5) 0;
    box-sizing: border-box;
  }

  .btn-container {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    width: 229px;
    flex-direction: row;
    justify-content: center;

    /* align-self: center;  */
    margin: var(--line-height-large) auto calc(2 * var(--line-height-large)) auto
  }